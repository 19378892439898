<template>
  <a-form-model
    class="niche-light-form"
    :model="form"
    :layout="layout"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    ref="lightForm"
    label-align="left"
    :rules="rules"
  >
    <template v-if="step == 1">
      <template v-if="!onlyShowGoods">
        <a-form-model-item label="供奉年数" prop="year_count" v-if="!modifyInfo">
          <a-input-number
            v-model="form.year_count"
            :min="1"
            :max="10"
            :precision="0"
            placeholder="请输入供奉年数"
            style="width: 100%"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="供奉人" prop="name">
          <a-input-group compact>
            <a-select
              style="width: 30%"
              v-model="form.order_type"
              placeholder="请选择"
              :options="nameTypeList"
            ></a-select>
            <a-input
              style="width: 70%"
              v-model="form.name"
              placeholder="请输入供奉姓名"
              :disabled="!form.order_type"
              :maxLength="[6, 4, 20][form.order_type - 1]"
            ></a-input>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="mobile">
          <a-input v-model="form.mobile" placeholder="请输入供奉人手机号码"></a-input>
        </a-form-model-item>
        <a-form-model-item label="祈福寄语" prop="note">
          <a-cascader
            v-model="form.note"
            placeholder="请选择祈福寄语"
            :options="wishTypeList"
          ></a-cascader>
        </a-form-model-item>
      </template>
      <a-form-model-item label="供奉物品" :wrapper-col="{ span: 20 }" v-if="showGoods && !modifyInfo">
        <a-table
          size="small"
          :row-key="(record) => record.id"
          :columns="columns"
          :data-source="goodsList"
          :pagination="pagination"
          :row-selection="{ selectedRowKeys, onChange: onSelectChange }"
          @change="handleTableChange"
          bordered
        ></a-table>
      </a-form-model-item>
    </template>
    <template v-else>
      <a-form-model-item label="上传凭证" prop="bill">
        <form-upload v-model="form.bill"></form-upload>
      </a-form-model-item>
      <a-form-model-item label="处理人" prop="processor" v-if="!modifyInfo">
        <a-input v-model="form.processor" placeholder="请输入处理人"></a-input>
      </a-form-model-item>
    </template>
    <slot></slot>
  </a-form-model>
</template>

<script>
import moment from "moment";
import { wishTypeList, getShowPrice, getIntegerAndDecimal, nameTypeList } from "./light";
import { dealGoods } from "../../common/constant/light/goods";

export default {
  name: "niche-order-form",
  props: {
    step: Number,
    onlyShowGoods: Boolean,
    modifyInfo: Boolean,
    info: Object,
    layout: {
      type: String,
      default: "horizontal",
    },
    labelCol: {
      type: Object,
      default: () => ({ span: 4 }),
    },
    wrapperCol: {
      type: Object,
      default: () => ({ span: 8 }),
    },
  },
  data() {
    return {
      form: {
        year_count: 1
      },
      rules: {
        year_count: [{ required: true, message: "请输入供奉年数", trigger: "blur" }],
        order_type: [{ required: true, message: "请选择类型", trigger: "change" }],
        name: [
          { required: true, message: "请输入供奉姓名", trigger: "blur" },
          { pattern: /^[^A-Za-z]*$/, message: "不支持输入英文" },
        ],
        mobile: [
          { required: true, message: "请输入供奉人手机号码", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择祈福方向", trigger: "change" }],
        note: [{ required: true, message: "请选择祈福寄语", trigger: "change" }],
        bill: [{ required: true, message: "请上传凭证", trigger: "change" }],
        processor: [{ required: true, message: "请输入处理人", trigger: "blur" }],
      },
      nameTypeList,
      wishTypeList,
      goodsList: [],
      columns: [
        { title: "物品名称", dataIndex: "name" },
        {
          title: "图片",
          dataIndex: "img",
          customRender: (text) => (text && text.split(",").length > 0 ? <s-img src={text.split(",")[0]} /> : "--"),
        },
        { title: "单价", dataIndex: "price", customRender: getShowPrice },
        {
          title: "数量",
          dataIndex: "count",
          customRender: (text, record) => {
            return (
              <a-input-number
                disabled={this.selectedRowKeys.indexOf(record.id) === -1}
                min={1}
                value={text}
                onChange={(val) => {
                  this.$set(record, "count", val);
                }}
              />
            );
          },
        },
        {
          title: "总金额",
          dataIndex: "total",
          customRender: (text, record) => {
            const index = this.selectedRowKeys.indexOf(record.id);
            const total = index === -1 ? 0 : record.count * record.price;
            return getShowPrice(total);
          },
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      showGoods: false,
      selectedRows: [],
    };
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRows.map((item) => item.id);
    },
  },
  watch: {
    info() {
      this.setForm();
    }
  },
  created() {
    this.getGoodsList();
    this.getGoodsSet();
    this.setForm();
  },
  methods: {
    setForm() {
      const info = this.info;
      if (info) {
        this.form = {
          ...info
        }
      } else {
        this.form = { year_count: 1 }
      }
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
    },
    onSelectChange(keys, rows) {
      this.selectedRows = rows;
      this.form.goods = rows;
    },
    getGoodsList() {
      const url = "/admin/mini-worship-item?pageSize=1000";
      this.$axios(url).then((res) => {
        const list = res.data;
        list.forEach((item) => {
          dealGoods(item);
          const { integer, decimal } = getIntegerAndDecimal(item.price);
          item.integer = integer;
          item.decimal = decimal;
          item.count = 1;
        });
        this.goodsList = list;
      });
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.lightForm.validate((valid) => {
          if (valid) {
            resolve(this.form);
          } else {
            reject();
          }
        });
      });
    },
    disabledDate(current) {
      return current && current < moment().startOf("day");
    },
    typeChange() {
      this.$set(this.form, "note", undefined);
    },
    getGoodsSet() {
      this.$axios({
        url: "/admin/mini-worship-item/get-config",
        method: "GET",
        params: {},
      }).then((res) => {
        if (res.error == 0) {
          const data = typeof res.data === "string" ? JSON.parse(res.data) : res.data;
          this.showGoods = !!data?.open;
        }
      });
    },
  },
};
</script>

<style lang="less">
.niche-light-form {
  padding: @padding-md;
}
</style>
